<template lang="pug">
  v-app
    Notify
    // v-system-bar(color="success" app style="place-content: center" dark height="60")
      div.text-body-1.text-center Conoce la ruta entre Castro y Chaitén ¡Viajes diarios Disponibles!
      // v-btn(text small) Más info
    v-app-bar(color="primary" dark app)
      v-spacer.hidden-sm-and-down
      router-link(to="/")
        v-img.shrink(alt="Logo" contain src="images/logo.svg" height="52" max-width="130")
      v-spacer
      v-slide-x-reverse-transition
        v-btn.hidden-md-and-up(text v-if="mostrar_boton" @click="mostrar_resumen = true")
          v-icon(left) mdi-playlist-edit
          | Reserva
      v-tooltip(v-if="timer" bottom)
        template(v-slot:activator="{ on, attrs }")
          v-progress-circular.hidden-md-and-up(size="24" :value="porcentaje" rotate="-90" v-bind="attrs" v-on="on" width="2")
            div.text-caption {{ minutos }}
        span Minutos restantes

    Breadcrumbs.flotante(v-if="$route.path.startsWith('/paso')" v-bind:class=" $vuetify.breakpoint.smAndDown ? 't56' : 't64'")
    v-main.grey.lighten-3.mt-12
      v-container(fluid)
        v-row(justify="center" align="start")
          v-col.pb-0(cols="12")
            v-img.mx-auto(src="images/banner.png" contain width="1500")
        v-row(justify="center")
          v-col(cols="12" md="8" xl="6")
            transition(name="fade" mode="out-in")
              router-view
          v-col.hidden-sm-and-down(v-if="esconder_resumen()" cols="4" lg="3")
            Resumen.fixed-resumen(@mostrar="mostrar_boton = true")
    v-dialog(v-model="mostrar_resumen" transition="dialog-top-transition")
      Resumen(@cerrar="mostrar_resumen = false")
    v-dialog(v-model="timeover" persistent max-width="600px")
      v-card
        v-card-title Tiempo agotado
        v-card-text
          div.text-body-1 Debe crear una nueva reserva.
          div.text-body-1 Recuerde que tiene <b>20 minutos</b> para finalizar la compra.
          v-img(src="images/ferry.png" contain height="120")
          div.font-italic.text-body-2.text-right Y se marchó... y a su barco le llamó libertad.
        v-card-actions
          v-spacer
          v-btn(color="primary" @click="resetear") ¡Entendido!
    Footer

</template>

<script>

import Breadcrumbs from '@/components/UI/Breadcrumbs'
import Notify from '@/components/UI/Notify'
import Resumen from '@/components/UI/Resumen'
import Footer from '@/components/UI/Footer'

export default {
  name: 'Inicio',

  components: {
    Breadcrumbs,
    Notify,
    Resumen,
    Footer
  },

  data: () => ({
    mostrar_resumen: false,
    mostrar_boton: false
  }),

  computed: {
    minutos () {
      return this.$store.state.timeover_minutos
    },

    timeover () {
      return this.$store.state.timeover_modal
    },

    timer () {
      return this.$store.state.timeover_timer !== null
    },

    porcentaje () {
      return this.minutos*100/this.$store.state.EXPIRACION_RESERVA
    }
  },

  methods: {
    esconder_resumen () {
      return !this.$route.path.startsWith('/final') && !this.$route.path.startsWith('/error') && !this.$route.path.startsWith('/pago')
    },

    resetear () {
      this.mostrar_resumen = false
      this.$store.dispatch('timeover')
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style>
  .flotante {
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  .t64 {
    top: 64px;
  }

  .t56 {
    top: 56px;
  }

  /*slide transition*/
  .slide-enter-active ,
  .slide-leave-active {
    transition: transform .4s ease-out, opacity .4s;
  }
  .slide-enter {
    transform: translateX(80%);
    opacity: 0;
  }
  .slide-leave-to {
    transform: translateX(-80%);
    opacity: 0;
  }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fixed-resumen {
  margin-right: 0px;
  max-width: 452px;
}
</style>